import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import HomePage from "./pages/HomePage";
import MediaPage from "./pages/MediaPage";
import CustomPage from "./pages/CustomPage";
import Header from "./Header";
import Footer from "./Footer";
import MinistryPage from "./pages/MinistryPage";
import MinistryPageItem from "./pages/MinistryPageItem";
import MediaPageItem from "./pages/MediaPageItem";

const App = () => {
    const renderPageRoute = () => {
        return <Switch>
            <Route path='/' exact={true} component={HomePage}/>
            <Route path='/home' component={HomePage}/>
            <Route path='/media' exact={true} component={MediaPage}/>
            <Route path='/media/:slug' component={MediaPageItem}/>
            <Route path='/ministry' exact={true} component={MinistryPage}/>
            <Route path='/ministry/:slug' component={MinistryPageItem}/>
            <Route path='/page/:slug' component={CustomPage}/>
        </Switch>
    };

    return <Fragment>
        <Header />
        { renderPageRoute() }
        <Footer />
    </Fragment>
};

export default connect(state => {
    return {
        page: state.page || {}
    }
})(App);
