import React, {Fragment} from 'react';
import {connect} from "react-redux";
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";
import PageHeader from "../PageHeader";

const MinistryPageItem = ({content, title}) => {

    return <Fragment>
        <PageHeader title={ title } subtitle="GECOP Ministries" />

        <section className="page-section background-white">
            <div className="container">

                <div className="text-center">
                    <div className="text-left paragraph">
                        <MarkupRender content={content} />
                    </div>
                </div>

            </div>
        </section>
    </Fragment>
};

export default connect(state => {
    const page = state.page || {};
    return {
        content: page.content,
        title: page.title
    };
})(MinistryPageItem);
