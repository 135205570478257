import React, {Fragment} from 'react';
import {connect} from "react-redux";
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";
import getBackgroundColor from "../../utils/getBackgroundColor";
import PageHeader from "../PageHeader";

const CustomPage = ({content, content2, content2_color, content3, content3_color, pageImage, headerType, title, subtitle}) => {
    const renderContentSection = (sectionContent, colorId) => {
        const bgColor = getBackgroundColor(colorId);
        return sectionContent ? <section className={`page-section background-${bgColor}`}>
            <div className="clear--lg"/>
            <div className="container text-left">
                <div className="paragraph">
                    <MarkupRender content={sectionContent} />
                </div>
            </div>
            <div className="clear--lg"/>
        </section> : null
    }

    return <Fragment>
        <PageHeader image={ pageImage } subtitle={ subtitle } title={ title } layout={ headerType } />
        { renderContentSection(content) }
        { renderContentSection(content2, content2_color) }
        { renderContentSection(content3, content3_color) }
    </Fragment>
};

export default connect(state => {
    const page = state.page || {};
    return {
        content: page.content,
        content2: page.field_1,
        content2_color: page.num_1,
        content3: page.field_2,
        content3_color: page.num_2,
        pageImage: page.image_1,
        headerType: page.num_5,
        title: page.title,
        subtitle: page.field_10,
    };
})(CustomPage);
