import React, {Fragment} from 'react';
import {connect} from "react-redux";
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";
import PageHeader from "../PageHeader";

const MediaPageItem = ({content, title, text }) => {
    return <Fragment>
        <PageHeader title={ title } subtitle="GECOP Videos" />

        <section className="page-section background-white pt-0">
            <div className="container">

                <div className="text-center">
                    <div className="paragraph"><MarkupRender content={content} /></div>
                    { text &&
                    <div className="paragraph">
                        <div className="clear--xxl"/>
                        <MarkupRender content={text} />
                    </div>
                    }
                </div>

                <div className="clear--xxl"/>
            </div>
        </section>
    </Fragment>
};

export default connect(state => {
    const page = state.page || {};
    return {
        content: page.content,
        title: page.title,
        text: page.field_1 || ''
    };
})(MediaPageItem);
