import React, {Fragment} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ToggleMenu = ({ animation, className, children, domElement, isOpen, onOuterClick }) => {
  const menuClass = 'toggle-menu';
  const menuClasses = classnames(menuClass, className, {
    [`${menuClass}--animate-${animation}`]: animation,
    [`${menuClass}--no-animation`]: !animation,
    [`${menuClass}--menuIsOpen`]: isOpen
  });

  const primaryCustomClass = className ? (className.split(' '))[0] : '';
  const backgroundClass = 'toggle-menu-background';
  const backgroundClasses = classnames(backgroundClass, {
    [`${primaryCustomClass}--${backgroundClass}`]: primaryCustomClass
  });

  const menu = <div className={ menuClasses }>{ children }</div>;

  const menuBackground = isOpen ? <div onClick={onOuterClick} className={backgroundClasses}/> : null;

  const menuParentElement = domElement || document.getElementById('root');

  return createPortal(
      <Fragment>
        {menuBackground}
        {menu}
      </Fragment>,
      menuParentElement
  )
};

ToggleMenu.propTypes = {
  animation: PropTypes.oneOf([
    'fade',
    'slideFromLeft',
    'slideFromRight',
    'slideUp',
    'slideDown'
  ]),
  className: PropTypes.string,
  children: PropTypes.any,
  domElement: PropTypes.string,
  isOpen: PropTypes.bool,
  onOuterClick: PropTypes.func
};

export default ToggleMenu;
