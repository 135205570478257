
export default {
  'en': {
    'homepage.flyerText': '<span>Waco</span><span>Awards</span><span>Show</span>',
    'prev-category': 'Previous',
    'next-category': 'Next',
    'round_tag_1': 'Preliminary Voting Round',
    'round_tag_2': 'Final Voting Round',
    'round_tag_3': 'The Waco Awards',
    'round_tag_4': 'Awards Winners',
    'vote_input_placeholder': 'Enter your vote here'
  }
};
