import React, {Fragment} from 'react';
import {connect} from "react-redux";
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";
import Tile from "../Tile";
import PageHeader from "../PageHeader";

const MediaPage = ({content, videos, title, subtitle }) => {
    const cssName = 'media-page';

    const videosRender = videos.map((video, i) => {
        return <a href={`/media/${video.slug}`} className="video" key={i}>
            <Tile image={ video.image } icon="ion-ios-play-circle" />
            <h4>
                { video.title }
                { video.excerpt && <span>{ video.excerpt }</span> }
            </h4>
        </a>
    });

    return <Fragment>
        <PageHeader title={ title } subtitle={subtitle} />

        <section className="page-section background-white">
            <div className="container">

                <div className="text-center">
                    <div className="paragraph"><MarkupRender content={content} /></div>
                </div>

                <div className="clear--xxl"/>

                <div className={`${cssName}__videos`}>
                    { videosRender }
                </div>
            </div>
        </section>
    </Fragment>
};

export default connect(state => {
    const page = state.page || {};
    return {
        content: page.content,
        title: page.title,
        subtitle: page.field_10,
        videos: state.posts || []
    };
})(MediaPage);
