import React from 'react';
import classnames from "classnames";

const PageHeader = ({ image, layout, subtitle, title }) => {
    const componentClass = 'pageHeader';
    const type = layout ? parseInt(layout) : 0;
    const isImageType = image && type === 5;

    const classes = classnames(componentClass,  {
        [`${componentClass}--withImage`]: isImageType,
        [`${componentClass}--withColor`]: type >= 2 && type <= 4,
        [`${componentClass}--green`]: type === 2,
        [`${componentClass}--black`]: type === 3,
        [`${componentClass}--gray`]: type === 4
    });

    const headerStyle = isImageType ? {
        backgroundImage: `url('/public/uploads/${image}')`
    } : {};

    return <div className={classes} style={headerStyle}>
        { type === 5 && <div className="overlay"/> }
        <h1>
            { title }
            { subtitle && isImageType && <span>{ subtitle }</span> }
        </h1>
        { subtitle && !isImageType && <div className="sub-header mt-5">{ subtitle }</div> }
    </div>
};

export default PageHeader;
