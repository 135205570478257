import React, {Fragment} from 'react';
import {connect} from "react-redux";
import moveIntoView from "../../utils/moveIntoView";
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";
import Tile from "../Tile";

const HomePage = ({content, contentTitle, contentSubtitle, urlAbout, urlMinistry, urlContactUs, theWordTitle, theWordVerse, theWordContent, pageImage, slides}) => {
    const cssName = 'home-page';
    const firstSectionId = 'first-section';
    const headerStyle = {
        backgroundImage: `url('/public/uploads/${pageImage}')`
    };

    const tilesRender = slides.map((slide,i) => {
        const showImage = parseInt(slide.field_2) && slide.image;

        return <Tile
            key={i}
            icon={ slide.field_1 }
            image={ showImage ? slide.image : null }
            title={ slide.name }
            text={ !showImage ? slide.text : null }
            url={ slide.field_3 }
        />
    });

    return <Fragment>
        <div className={`${cssName}__header`} style={headerStyle}>
            <div className="overlay"/>
            <div className={`${cssName}__hero-banner`}>
                <span>Welcome To</span>
                <h1>God's <span className="color-gold">Exciting</span> Cathedral of <span className="color-green">Praise</span></h1>
                <div><i className="fa fa-chevron-down" onClick={() => moveIntoView(firstSectionId)}/></div>
            </div>
        </div>
        <section className="page-section background-gray" id={firstSectionId}>
            <div className="container">
                <div className={`${cssName}__tiles`}>
                    { tilesRender }
                </div>

                <div className="clear--xxl"/>

                <div className={`${cssName}__welcome`}>
                    <div className="sub-header">{ contentSubtitle }</div>
                    <h2>{ contentTitle }</h2>
                    <div className="paragraph"><MarkupRender content={content} /></div>
                </div>

                <div className="clear--xxl"/>

                <div className={`${cssName}__links`}>
                    <a href={ urlAbout } target="_blank">Learn More</a>
                    <a href={ urlMinistry } target="_blank">Our Ministry</a>
                    <a href={ urlContactUs } target="_blank">Contact Us</a>
                </div>
            </div>
            <div className="clear--xxl"/>
        </section>

        <section className="page-section background-black color-white">
            <div className="clear--xxl"/>
            <div className="container text-center">
                <h2>{ theWordTitle }</h2>
                <div className="clear--xl"/>
                <div className="paragraph"><MarkupRender content={theWordContent} /></div>
                <div className="clear--xxl"/>
                <div className="sub-header color-white">{ theWordVerse }</div>
            </div>
            <div className="clear--xxl"/>
        </section>
    </Fragment>
};

export default connect(state => {
    const page = state.page || {};
    return {
        content: page.content,
        contentTitle: page.field_3,
        contentSubtitle: page.field_4,
        urlAbout: page.field_5,
        urlMinistry: page.field_6,
        urlContactUs: page.field_7,
        theWordTitle: page.field_8,
        theWordVerse: page.field_9,
        theWordContent: page.field_10,
        pageImage: page.image_1,
        slides: state.slides || []
    };
})(HomePage);
