import React, { useState } from 'react';
import classnames from 'classnames';
import {connect} from "react-redux";
import {ReactSVG} from "react-svg";
import ToggleMenu from "./refactor/ToggleMenu";

const Header = ({commonProps}) => {
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);
    const {navItems} = commonProps;
    const componentClass = 'header';
    const classes = classnames(componentClass,  {
        [`${componentClass}--isOpen`]: isMenuOpen
    })

    const renderedLinks = Object.values(navItems || {}).map((n,i) => {
        const { href, title, new_window } = n;
        return <a key={i} href={href} target={ parseInt(new_window) ? "_blank" : "_self"}>{ title }</a>;
    });

    return <div className={classes}>
        <a href="/"><ReactSVG src="/public/assets/img/gecop-emblem.svg" className={`${componentClass}__logo`} /></a>
        <h1>GECOP Church</h1>
        <div className={`${componentClass}__menu-btn`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <i className={ isMenuOpen ? "ion-ios-close" : "ion-ios-menu" } />
        </div>
        <div className={`${componentClass}__navigation`}>
            { renderedLinks }
        </div>

        <ToggleMenu
            isOpen={isMenuOpen}
            animation="slideFromRight"
            onOuterClick={() => setIsMenuOpen(false)}
        >{ renderedLinks }</ToggleMenu>
    </div>
};

export {
    Header as DisconnectedNavigation
};

export default connect(state => {
    return {
        commonProps: state.commonProps || {}
    };
})(Header);
