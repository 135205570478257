import {initializeNutasticApp} from "@nutastic/nutastic-react-app";
import {extractElementData, fetchApiData} from "@nutastic/nutastic-react-app/src/utils";
import AppLoading from "./components/AppLoading";
import App from "./components/App";
import messages from "./i18n/messages";

const init = async () => {
    console.log('initializing page');
    await initializeNutasticApp({
        AppComponent: App,
        LoadingComponent: AppLoading,
        initialState: extractElementData(),
        decorateStateFn: fetchApiData,
        messages,
        reducers: []
    });
}

init();
