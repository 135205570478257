
const backgroundColors = {
    1: 'black',
    2: 'green',
    3: 'white',
    4: 'gray'
}
const getBackgroundColor = id => backgroundColors[id] || 'white';

export default getBackgroundColor;
