import React, {Fragment} from 'react';
import {connect} from "react-redux";
import Tile from "../Tile";
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";
import PageHeader from "../PageHeader";

const MinistryPage = ({content, members, ministriesText, posts, title, subtitle}) => {
    const cssName = 'ministry-page';

    const membersRender = members.map((slide,i) => {
        return <Tile
            key={i}
            image={ slide.image }
            layout="memberLayout"
            title={ slide.name }
            text={ slide.text }
        />
    });

    const ministryList = posts.map((item,i) => <li key={i}>
        <a href={`/ministry/${item.slug}`}>{ item.title }</a>
    </li>);

    return <Fragment>
        <PageHeader title={ title } subtitle={subtitle} />

        <section className="page-section background-white">
            <div className="container">

                <div className="text-center">
                    <div className="text-left paragraph">
                        <MarkupRender content={content} />
                    </div>
                </div>

                <div className="clear--xxl"/>

                <div className={`${cssName}__ministers`}>
                    <div className="text-center"><h3>Our Ministers</h3></div>
                    <div className="clear--lg"/>
                    <div className={`${cssName}__tiles`}>
                        { membersRender }
                    </div>
                </div>
            </div>
        </section>

        <section className="page-section background-black color-white">
            <div className="clear--xxl"/>
            <div className="container text-center">
                <h2>Our Ministries</h2>
                <div className="clear--xl"/>
                {ministriesText &&
                <div className="text-center paragraph">
                    <MarkupRender content={ministriesText} />
                    <div className="clear--xl" />
                </div>
                }
                <ul className="ministry-list">
                    { ministryList }
                </ul>
                <div className="clear--xxl"/>
            </div>
            <div className="clear--xxl"/>
        </section>
    </Fragment>
};

export default connect(state => {
    const page = state.page || {};
    return {
        content: page.content,
        title: page.title,
        ministriesText: page.field_3,
        subtitle: page.field_10,
        members: state.members || [],
        posts: state.posts || []
    };
})(MinistryPage);
