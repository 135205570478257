import React from 'react';
import classnames from "classnames";

const Tile = ({ icon, image, layout, text, title, url }) => {
    const componentClass = 'tile';
    const HTMLTag = url ? 'a' : 'div';

    const classes = classnames(componentClass,  {
        [`${componentClass}--withImage`]: image,
        [`${componentClass}--${layout}`]: layout
    });

    const slideStyle = {
        backgroundImage: `url('/public/uploads/${image}')`
    }

    return <HTMLTag href={ url } className={ classes } style={ image ? slideStyle : {}}>
        { image && <div className="overlay" /> }
        { !image && icon && <i className={icon} /> }
        <h4>{ title }</h4>
        { text && <span>{ text }</span> }
    </HTMLTag>
};

export default Tile;
