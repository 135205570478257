import React from 'react';
import {connect} from "react-redux";
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";

const Footer = ({ navItems, settings }) => {
    const {
        contactForm_content,
        contactForm_title,
        contactInfo_address,
        contactInfo_details
    } = settings;
    const componentClass = 'footer';
    const copyrightYear = new Date().getFullYear();

    const renderedLinks = Object.values(navItems || {}).map((n,i) => {
        const { href, title, new_window } = n;
        return <a key={i} href={href} target={ parseInt(new_window) ? "_blank" : "_self"}>{ title }</a>;
    });

    return <footer>
        <section className="contact-section">
            <div className="container">
                <div className="contact-form">
                    <div className="contact-form__title">
                        <h1>{ contactForm_title }</h1>
                        <div><MarkupRender content={contactForm_content}/></div>
                    </div>
                    <div className="contact-form__border"/>
                    <form>
                        <div>
                            <input name="name" placeholder="Full Name"/>
                            <input name="email" placeholder="Email"/>
                            <input name="phone" placeholder="Phone"/>
                            <input name="message" placeholder="Enter Short Message"/>
                        </div>
                        <button className="btn btn-primary">Send</button>
                    </form>
                </div>
            </div>
        </section>

        <section className={componentClass}>
            <div className="container">
                <div className={`${componentClass}__left-side`}>
                    <div className="row">
                        <div className="col-md-4">
                            <h5>Quick Links</h5>
                            <div className={`${componentClass}__links`}>{ renderedLinks }</div>
                        </div>
                        <div className="col-md-4">
                            <h5>Worship With Us</h5>
                            <div><MarkupRender content={contactInfo_address}/></div>
                        </div>
                        <div className="col-md-4 text-md-right">
                            <h5>Contact Us</h5>
                            <div><MarkupRender content={contactInfo_details}/></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </footer>
};

export default connect(state => {
    const commonProps = state.commonProps || {};
    return {
        settings: commonProps.settings || {},
        navItems: commonProps.footerNavItems || {}
    };
})(Footer);

